import React from "react";

const Header = () => {
    return (
        <header
            style={{
                background: "black",
                color: "white",
                padding: "5px 10px",
                textAlign: "center",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                position: "sticky",
                top: "0",
                zIndex: "1000",
                fontFamily: "'Arial', sans-serif",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Distribute space
            }}
        >
            {/* About on the left */}
            <a
                href="https://earthintel.news/about-earth-intelligence/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "14px",
                    fontWeight: "bold",
                }}
            >
                About
            </a>

            {/* Title in the center */}
            <h1
                style={{
                    margin: "0",
                    fontSize: "18px",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                }}
            >
                <a
                    href="https://earthintel.news/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white",
                        textDecoration: "none",
                    }}
                >
                    Earth Intelligence
                </a>
            </h1>

            {/* News on the right */}
            <a
                href="https://earthintel.news/page/2/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "14px",
                    fontWeight: "bold",
                }}
            >
                News
            </a>
        </header>
    );
};

export default Header;