// Globe.jsx
import React, { useEffect, useState, useRef } from "react";
import Globe from "react-globe.gl";
import { hudStyles, popupStyles } from "./styles";

const formatNumber = (number) =>
	number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "N/A";

// Bottom container modes
const BOTTOM_MODE = {
	NONE: "none",
	ITEMS: "items",
	DETAILS: "details",
};

function Earth({ category }) {
	const [data, setData] = useState([]);
	const [conflictData, setConflictData] = useState([]);
	const [cyberData, setCyberData] = useState([]);
	const [environmentData, setEnvironmentData] = useState([]);
	const [newsData, setNewsData] = useState([]);
	const [economicData, setEconomicData] = useState([]);
	const [infrastructureData, setInfrastructureData] = useState([]);
	const [wondersData, setWondersData] = useState([]);
	const [spaceportsData, setSpaceportsData] = useState([]);
	const [moonData, setMoonData] = useState(null);

	const [selectedPoint, setSelectedPoint] = useState(null);
	const [relatedPoints, setRelatedPoints] = useState([]);
	const [contextParagraph, setContextParagraph] = useState("");
	const [relatedHeader, setRelatedHeader] = useState("");
	const [mainConflictPoint, setMainConflictPoint] = useState(null);

	const globeRef = useRef();
	const [view, setView] = useState("earth");
	const [bottomMode, setBottomMode] = useState(BOTTOM_MODE.NONE);

	// Distinguish if detail popup came from "list" or "map"
	const [detailSource, setDetailSource] = useState(null);

	// For merged (News + Environment + Cyber) items
	const [itemsForView, setItemsForView] = useState([]);

	// SENTRY container visible on Earth by default
	const [showSentry, setShowSentry] = useState(true);

	// SENTRY data from /sentry.json
	const [sentryData, setSentryData] = useState({ environment: [], other: [] });

	// Focus Mode states
	const [focusMode, setFocusMode] = useState(false);
	const [focusCategory, setFocusCategory] = useState(null);

	useEffect(() => {
		loadData();
	}, []);

	/**
	 * Helper to parse date strings for sorting
	 */
	const parseDateValue = (dateStr) => {
		if (!dateStr) return 0;
		const parsed = Date.parse(dateStr);
		return isNaN(parsed) ? 0 : parsed;
	};

	/**
	 * Fetch JSON with error handling
	 */
	const fetchJSON = async (url, onSuccess, label) => {
		try {
			const res = await fetch(url);
			if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`);
			const text = await res.text();
			const json = JSON.parse(text);
			onSuccess(json);
		} catch (err) {

		}
	};

	const loadData = async () => {
		await fetchJSON("/country_profiles.json", setData, "country data");
		await fetchJSON("/conflict.json", setConflictData, "conflict data");
		await fetchJSON("/cyberattacks.json", setCyberData, "cyberattack data");

		// environment
		await fetchJSON("/environment.json", (jsonData) => {
			const transformed = jsonData.map((e) => ({
				geometry: { coordinates: e.coordinates },
				properties: {
					date: e.date,
					event_name: e.event_name,
					location: e.location,
					overview: e.overview,
				},
			}));
			setEnvironmentData(transformed);
		}, "environment data");

		// news
		await fetchJSON("/news.json", setNewsData, "news data");

		// countries & islands
		await fetchJSON("/economicdata.json", setEconomicData, "economic data");

		// ports
		await fetchJSON("/ports.json", (jsonData) => {
			const transformed = jsonData.ports.map((port) => ({
				geometry: { coordinates: [port.coordinates.longitude, port.coordinates.latitude] },
				properties: {
					infrastructure_title: port.name,
					summary: port.overview,
					port_rank: port.rank,
					port_unit: port.volume.unit,
					port_volume_2022: port.volume["2022"],
					port_volume_2023: port.volume["2023"],
				},
			}));
			setInfrastructureData(transformed);
		}, "ports data");

		// wonders
		await fetchJSON("/worldwonders.json", (jsonData) => {
			const transformed = jsonData.wonders.map((w) => ({
				geometry: { coordinates: [w.coordinates.longitude, w.coordinates.latitude] },
				properties: {
					wonder_title: w.name,
					overview: w.overview,
					history: w.history,
				},
			}));
			setWondersData(transformed);
		}, "world wonders data");

		// spaceports
		await fetchJSON("/spaceports.json", (jsonData) => {
			const transformed = jsonData.spaceports.map((sp) => ({
				geometry: { coordinates: [sp.coordinates.longitude, sp.coordinates.latitude] },
				properties: {
					spaceport_title: sp.name,
					recent_launches: sp.recent_launches,
					recent_activity: sp.recent_activity,
					capabilities: sp.capabilities,
					description: sp.description,
					history: sp.history,
				},
			}));
			setSpaceportsData(transformed);
		}, "spaceports data");

		// moon
		await fetchJSON("/mooondatabase.json", setMoonData, "moon data");

		// SENTRY
		await fetchJSON("/sentry.json", (json) => {
			const sortedEnvironment = (json.environment || []).sort(
				(a, b) => parseDateValue(b.date) - parseDateValue(a.date)
			);
			const sortedOther = (json.other || []).sort(
				(a, b) => parseDateValue(b.date) - parseDateValue(a.date)
			);
			setSentryData({
				environment: sortedEnvironment,
				other: sortedOther,
			});
		}, "sentry data");
	};

	/**
	 * Returns the points to render based on focus mode & category
	 */
	const getPointsData = () => {
		if (view !== "earth") return [];

		// If not in focus mode or no category => show everything
		if (!focusMode || !focusCategory) {
			return [
				...newsData,
				...conflictData,
				...data,
				...wondersData,
				...environmentData,
				...cyberData,
				...infrastructureData,
				...spaceportsData,
				...relatedPoints,
			];
		}

		// In focus mode, pick the dataset
		switch (focusCategory) {
			case "news":
				return newsData;
			case "conflicts":
				// Include conflictData + relatedPoints
				return [...conflictData, ...relatedPoints];
			case "countries":
				return data; // countries & islands
			case "wonders":
				return wondersData;
			case "environment":
				return environmentData;
			case "cyber":
				return cyberData;
			case "ports":
				return infrastructureData;
			case "spaceports":
				return spaceportsData;
			default:
				return [];
		}
	};

	/**
	 * Merge news, environment, cyber => sort newest->oldest
	 */
	const openNewsEnvironmentCyber = () => {
		const merged = [...newsData, ...environmentData, ...cyberData].map((item) => {
			const dateVal = parseDateValue(item.properties?.date);
			return { ...item, _dateVal: dateVal };
		});
		merged.sort((a, b) => b._dateVal - a._dateVal);

		setItemsForView(merged);
		setBottomMode(BOTTOM_MODE.ITEMS);
		setShowSentry(false);
	};

	/**
	 * Spin the globe
	 */
	const spinGlobeToPoint = (point) => {
		if (!point?.geometry?.coordinates || !globeRef.current) return;
		const [lng, lat] = point.geometry.coordinates;
		globeRef.current.pointOfView({ lat, lng, altitude: 2.2 }, 2000);
	};

	/**
	 * On marker click => open detail popup
	 */
	const handlePointClick = (point, source = "map") => {
		if (view !== "earth") return;
		if (!point?.properties) return;

		setShowSentry(false);
		setSelectedPoint(point);
		setDetailSource(source);

		const newRelatedPoints = point.properties?.related_points || [];
		if (point.properties.is_main_conflict) {
			setMainConflictPoint(point);
			setContextParagraph("");
			setRelatedHeader(point.properties.title || "");
			setRelatedPoints(newRelatedPoints);
		} else if (relatedPoints.includes(point)) {
			setContextParagraph(point.properties?.context || "No additional context available.");
			setRelatedHeader(point.properties.title || "");
			if (newRelatedPoints.length > 0) {
				setRelatedPoints(newRelatedPoints);
			} else {
				if (mainConflictPoint) {
					setRelatedPoints([mainConflictPoint, point]);
				} else {
					setRelatedPoints([point]);
				}
			}
		} else {
			setContextParagraph("");
			setRelatedHeader("");
			setRelatedPoints([]);
		}

		// Mark selected
		const selectPoint = (arr) =>
			arr.map((p) => (p === point ? { ...p, selected: true } : { ...p, selected: false }));

		setData((prev) => selectPoint(prev));
		setConflictData((prev) => selectPoint(prev));
		setCyberData((prev) => selectPoint(prev));
		setEnvironmentData((prev) => selectPoint(prev));
		setNewsData((prev) => selectPoint(prev));
		setEconomicData((prev) => selectPoint(prev));
		setInfrastructureData((prev) => selectPoint(prev));
		setWondersData((prev) => selectPoint(prev));
		setSpaceportsData((prev) => selectPoint(prev));

		setBottomMode(BOTTOM_MODE.DETAILS);
	};

	/**
	 * From merged list => spin & show details
	 */
	const handleItemClick = (item) => {
		setDetailSource("list");
		const allPoints = [
			...newsData,
			...conflictData,
			...data,
			...wondersData,
			...environmentData,
			...cyberData,
			...infrastructureData,
			...spaceportsData,
			...relatedPoints,
		];
		const found = allPoints.find((p) => p.properties === item.properties);
		if (found) {
			spinGlobeToPoint(found);
			setTimeout(() => {
				handlePointClick(found, "list");
			}, 2100);
		}
	};

	/**
	 * Renders merged items
	 */
	const renderMergedItems = () => {
		return (
			<div style={{ padding: "10px" }}>
				<button
					style={{ ...popupStyles.closeButton, fontSize: "12px", padding: "3px 6px" }}
					onClick={() => {
						setBottomMode(BOTTOM_MODE.NONE);
						setShowSentry(true);
					}}
				>
					Close
				</button>
				<h3>Recent News</h3>
				{itemsForView.map((item, idx) => {
					const props = item.properties || {};
					const dateStr = props.date || "No Date";
					const label = props.title || props.event_name || "Unnamed";

					return (
						<div key={idx} style={{ marginBottom: "8px" }}>
							<div style={{ fontWeight: "bold" }}>{dateStr}</div>
							<div>{label}</div>
							<button
								style={{
									...popupStyles.closeButton,
									marginTop: "3px",
									fontSize: "12px",
									padding: "3px 6px",
								}}
								onClick={() => handleItemClick(item)}
							>
								View
							</button>
							<hr style={{ border: "1px solid #00BFFF", margin: "5px 0" }} />
						</div>
					);
				})}
			</div>
		);
	};

	/**
	 * Renders content for selected marker
	 */
	const renderContent = () => {
		if (!selectedPoint) return null;
		const props = selectedPoint.properties;

		if (relatedPoints.includes(selectedPoint) && !props?.is_main_conflict) {
			return (
				<div>
					<strong>Context:</strong>{" "}
					{contextParagraph || "No additional context available."}
				</div>
			);
		}
		if (props?.is_main_conflict) {
			const { conflict_name, start_date, recent_activity, background } =
				props?.details || {};
			return (
				<div>
					<div>
						<strong>Conflict Name:</strong> {conflict_name || "N/A"}
					</div>
					<div>
						<strong>Start Date:</strong> {start_date || "N/A"}
					</div>
					<div>
						<strong>Recent Activity:</strong>{" "}
						{recent_activity?.length
							? recent_activity.map((item, i) => (
								<div key={i}>
									{item.date}: {item.event}
								</div>
							))
							: "No recent activity"}
					</div>
					<div>
						<strong>Background:</strong> {background || "N/A"}
					</div>
				</div>
			);
		}
		// environment
		if (props?.event_name && props?.overview) {
			return (
				<div>
					<div>
						<strong>Event Name:</strong> {props.event_name || "N/A"}
					</div>
					<div>
						<strong>Location:</strong> {props.location || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Overview:</strong>
						<br />
						{props.overview || "N/A"}
					</div>
				</div>
			);
		}
		// cyber
		if (
			props?.background_paragraph &&
			props?.overview_of_the_hack &&
			props?.damage_assessments_and_fallout &&
			props?.security_measures
		) {
			return (
				<div>
					<div>
						<strong>Title:</strong> {props.title || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Background:</strong>
						<br />
						{props.background_paragraph || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Overview of the Hack:</strong>
						<br />
						{props.overview_of_the_hack || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Damage Assessments & Fallout:</strong>
						<br />
						{props.damage_assessments_and_fallout || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Security Measures:</strong>
						<br />
						{props.security_measures || "N/A"}
					</div>
				</div>
			);
		}
		// news
		if (props?.title && props?.paragraph) {
			return (
				<div>
					<div>
						<strong>Title:</strong> {props.title || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Details:</strong>
						<br />
						{props.paragraph || "N/A"}
					</div>
				</div>
			);
		}
		// countries & islands
		if (props?.economic_title && props?.details) {
			return (
				<div>
					<div>
						<strong>Title:</strong> {props.economic_title || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Details:</strong>
						<br />
						{props.details || "N/A"}
					</div>
				</div>
			);
		}
		// ports
		if (
			props?.infrastructure_title &&
			props?.summary &&
			typeof props.port_rank !== "undefined"
		) {
			return (
				<div>
					<div>
						<strong>Name:</strong> {props.infrastructure_title || "N/A"}
					</div>
					<div>
						<strong>Rank:</strong> {props.port_rank || "N/A"}
					</div>
					<div>
						<strong>Unit:</strong> {props.port_unit || "N/A"}
					</div>
					<div>
						<strong>2022 Volume:</strong> {props.port_volume_2022 || "N/A"}
					</div>
					<div>
						<strong>2023 Volume:</strong> {props.port_volume_2023 || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Overview:</strong>
						<br />
						{props.summary || "N/A"}
					</div>
				</div>
			);
		}
		// wonders
		if (props?.wonder_title && props?.overview && props?.history) {
			return (
				<div>
					<div>
						<strong>Name:</strong> {props.wonder_title || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Overview:</strong>
						<br />
						{props.overview || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>History:</strong>
						<br />
						{props.history || "N/A"}
					</div>
				</div>
			);
		}
		// spaceports
		if (
			props?.spaceport_title &&
			props?.description &&
			props?.recent_launches &&
			props?.recent_activity &&
			props?.capabilities &&
			props?.history
		) {
			return (
				<div>
					<div>
						<strong>Title:</strong> {props.spaceport_title || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Recent Launches:</strong>
						<br />
						{props.recent_launches.length
							? props.recent_launches.join(", ")
							: "None"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Recent Activity:</strong>
						<br />
						{props.recent_activity || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Capabilities:</strong>
						<br />
						{props.capabilities || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>Description:</strong>
						<br />
						{props.description || "N/A"}
					</div>
					<div style={{ marginTop: "10px" }}>
						<strong>History:</strong>
						<br />
						{props.history || "N/A"}
					</div>
				</div>
			);
		}

		// fallback
		const population = formatNumber(props?.population);
		return (
			<div>
				<div>
					<strong>Population:</strong> {population}
				</div>
				<div>
					<strong>Median Age:</strong> {props?.median_age || "N/A"}
				</div>
				<div>
					<strong>Land Area:</strong> {formatNumber(props?.land_area)} km²
				</div>
			</div>
		);
	};

	const renderMoonData = () => {
		if (!moonData) return <div>Loading Moon Data...</div>;
		const { history, resources, moon_initiatives, facts } = moonData;
		return (
			<div
				style={{
					fontFamily: "Calibri, sans-serif",
					fontSize: "14px",
					lineHeight: "1.4",
					color: "white",
				}}
			>
				<h3>History</h3>
				<p>{history.summary}</p>
				<ul>
					{history.timeline.map((item, idx) => (
						<li key={idx}>
							<strong>{item.period}:</strong> {item.details}
						</li>
					))}
				</ul>
				<hr style={{ border: "1px solid #555", margin: "10px 0" }} />

				<h3>Resources</h3>
				<p>
					<strong>Minerals:</strong>
				</p>
				<ul>
					{resources.scientific.minerals.map((m, idx) => (
						<li key={idx}>{m}</li>
					))}
				</ul>
				<p>
					<strong>Water:</strong> {resources.scientific.water}
				</p>
				<p>
					<strong>Terrain:</strong> {resources.geographic.terrain}
				</p>
				<p>
					<strong>Gravity:</strong> {resources.geographic.gravity}
				</p>
				<hr style={{ border: "1px solid #555", margin: "10px 0" }} />

				<h3>Moon Initiatives</h3>
				<ul>
					{moon_initiatives.map((init, idx) => (
						<li key={idx}>
							<strong>{init.name}</strong> -{" "}
							{init.organization ||
								(init.organizations && init.organizations.join(", "))}
							<br />
							Goal: {init.goal || init.goals}
						</li>
					))}
				</ul>
				<hr style={{ border: "1px solid #555", margin: "10px 0" }} />

				<h3>Facts</h3>
				<p>
					<strong>Distance from Earth:</strong> {facts.distance_from_earth}
				</p>
				<p>
					<strong>Size:</strong> {facts.size}
				</p>
				<p>
					<strong>Rotation:</strong> {facts.rotation}
				</p>
				<p>
					<strong>Daytime Temperature:</strong> {facts.temperature.daytime}
				</p>
				<p>
					<strong>Nighttime Temperature:</strong> {facts.temperature.nighttime}
				</p>
				<p>
					<strong>Influence on Earth:</strong>
				</p>
				<ul>
					{facts.influence_on_earth.map((inf, idx) => (
						<li key={idx}>{inf}</li>
					))}
				</ul>
				<p>
					<strong>Exploration Milestone:</strong> {facts.exploration_milestone}
				</p>
			</div>
		);
	};

	/**
	 * Toggle Earth <-> Moon
	 */
	const toggleView = () => {
		if (view === "earth") {
			setShowSentry(false);
		} else {
			setShowSentry(true);
		}
		setView((prevView) => (prevView === "earth" ? "moon" : "earth"));

		setSelectedPoint(null);
		setRelatedPoints([]);
		setContextParagraph("");
		setRelatedHeader("");
		setMainConflictPoint(null);
		setBottomMode(BOTTOM_MODE.NONE);
	};

	/**
	 * SENTRY container => environment (left), other (right)
	 */
	const renderSentryContainer = () => {
		return (
			<div
				style={{
					...popupStyles.container,
					position: "absolute",
					bottom: "32px",
					left: "50%",
					transform: "translateX(-50%)",
					width: "80%",
					height: "90px",
					zIndex: 998,
					margin: 5,
					overflowY: "auto",
				}}
			>
				<div
					style={{
						background: "transparent",
						color: "white",
						padding: "0px 0",
						fontWeight: "bold",
						fontSize: "15px",
						borderBottom: "1px solid #00BFFF",
						textAlign: "center",
						fontFamily: "Calibri, sans-serif",
					}}
				>
					SENTRY
				</div>

				<div style={{ display: "flex" }}>
					{/* environment */}
					<div
						style={{
							flex: 1,
							borderRight: "1px solid #00BFFF",
							padding: "8px",
							fontFamily: "Calibri, sans-serif",
							fontSize: "14px",
							color: "white",
							textAlign: "center",
						}}
					>
						<h4 style={{ margin: "0 0 4px 0" }}>Environment</h4>
						{(sentryData.environment || []).map((item, idx) => (
							<div key={idx} style={{ marginBottom: "4px", textAlign: "left" }}>
								<div>
									<strong>Date:</strong> {item.date}
								</div>
								<div>
									<strong>Event:</strong> {item.event}
								</div>
								<hr style={{ border: "1px solid #00BFFF", margin: "2px 0" }} />
							</div>
						))}
					</div>

					{/* other */}
					<div
						style={{
							flex: 1,
							padding: "8px",
							fontFamily: "Calibri, sans-serif",
							fontSize: "14px",
							color: "white",
							textAlign: "center",
						}}
					>
						<h4 style={{ margin: "0 0 4px 0" }}>Other</h4>
						{(sentryData.other || []).map((item, idx) => (
							<div key={idx} style={{ marginBottom: "4px", textAlign: "left" }}>
								<div>
									<strong>Date:</strong> {item.date}
								</div>
								<div>
									<strong>Details:</strong> {item.details}
								</div>
								<hr style={{ border: "1px solid #00BFFF", margin: "2px 0" }} />
							</div>
						))}
					</div>
				</div>
			</div>
		);
	};

	/**
	 * Renders top HUD with:
	 *   - Arrows + Earth/Moon label
	 *   - Focus or All button if Earth
	 *   - Category row if Earth
	 *   - Population text
	 */
	const renderTopHud = () => {
		const hudCategories = [
			{ key: "news", label: "News", color: "#FFA500" },
			{ key: "conflicts", label: "Conflicts", color: "black" },
			{ key: "countries", label: "Countries & Islands", color: "blue" },
			{ key: "wonders", label: "World Wonders", color: "#00FF80" },
			{ key: "environment", label: "Environment", color: "#87CEFA" },
			{ key: "cyber", label: "Cyber", color: "green" },
			{ key: "ports", label: "Ports", color: "brown" },
			{ key: "spaceports", label: "Spaceports", color: "#8A2BE2" },
			{ key: "selected", label: "Selected", color: "#FF5F1F" },
		];

		const arrowStyle = {
			cursor: "pointer",
			fontSize: "18px",
			fontWeight: "bold",
			color: "#FFD700",
			userSelect: "none",
			margin: "0 5px",
		};

		const handleFocusToggle = () => {
			if (focusMode) {
				setFocusMode(false);
				setFocusCategory(null);
			} else {
				setFocusMode(true);
				setFocusCategory(null);
			}
		};

		return (
			<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
				{/* Row 1: Arrows + Earth/Moon label, plus Focus button on right if Earth */}
				<div
					style={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						position: "relative",
					}}
				>
					{/* Left Arrow */}
					<span style={arrowStyle} onClick={toggleView} title="Switch View">
						&lt;
					</span>

					{/* Earth or Moon label */}
					<div
						style={{
							fontSize: "14px",
							userSelect: "none",
							fontWeight: "bold",
							margin: "0 5px",
						}}
					>
						{view === "earth" ? "Earth Watch" : "Moon Watch"}
					</div>

					{/* Right Arrow */}
					<span style={arrowStyle} onClick={toggleView} title="Switch View">
						&gt;
					</span>

					{/* Focus / All button (only if Earth) */}
					{view === "earth" && (
						<div
							style={{
								position: "absolute",
								right: "20px",
								cursor: "pointer",
								padding: "2px 6px",
								border: "1px solid #00BFFF", // SCI-FI BLUE
								borderRadius: "4px",
								fontSize: "12px",
								userSelect: "none",
								color: focusMode ? "#FFD700" : "white",
								backgroundColor: "transparent",
							}}
							onClick={handleFocusToggle}
							title="Toggle Focus Mode"
						>
							{focusMode ? "All" : "Focus"}
						</div>
					)}
				</div>

				{/* Row 2: category row + population text if Earth */}
				{view === "earth" && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginTop: "3px",
							userSelect: "none",
							gap: "5px",
						}}
					>
						{/* Categories row */}
						<div
							style={{
								display: "flex",
								alignItems: "center",
								fontSize: "8px",
								flexWrap: "wrap",
								justifyContent: "center",
								gap: "8px",
							}}
						>
							{hudCategories.map((cat) => {
								const isClickable = focusMode && cat.key !== "selected";
								const isSelected = focusCategory === cat.key && focusMode; // highlight if chosen

								return (
									<div
										key={cat.key}
										style={{
											display: "flex",
											alignItems: "center",
											whiteSpace: "nowrap",
											cursor: isClickable ? "pointer" : "default",
											textDecoration: isClickable ? "underline" : "none",
											borderBottom: isSelected ? "2px solid #FFD700" : "none",
										}}
										onClick={() => {
											if (isClickable) {
												setFocusCategory(cat.key);
											}
										}}
									>
										<div
											style={{
												width: "16px",
												height: "16px",
												backgroundColor: cat.color,
												marginRight: "3px",
											}}
										/>
										{cat.label}
									</div>
								);
							})}
						</div>

						{/* Population text */}
						<div style={{ fontSize: "9px", color: "white" }}>
							Earth Population: 8,200,748,700
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<div style={{ position: "relative" }}>
			{/* HUD container with custom top layout */}
			<div style={hudStyles.container}>{renderTopHud()}</div>

			<Globe
				ref={globeRef}
				globeImageUrl={
					view === "earth"
						? "/earthimagemap.jpg"
						: "/lunar_surfaceglobalmap.jpg"
				}
				backgroundColor="black"
				pointsData={view === "earth" ? getPointsData() : []}
				arcsData={
					view === "earth"
						? (() => {
							if (relatedPoints.length > 0 && selectedPoint) {
								return relatedPoints
									.filter((rp) => rp !== selectedPoint)
									.map((relatedPoint) => ({
										startLat: relatedPoint.geometry.coordinates[1],
										startLng: relatedPoint.geometry.coordinates[0],
										endLat: selectedPoint.geometry.coordinates[1],
										endLng: selectedPoint.geometry.coordinates[0],
									}));
							}
							return [];
						})()
						: []
				}
				arcColor={() => ["#00FF80", "#00FF80"]}
				arcDashLength={1}
				arcDashGap={0}
				arcStroke={1.5}
				pointLat={(d) => d.geometry?.coordinates[1]}
				pointLng={(d) => d.geometry?.coordinates[0]}
				pointColor={(d) => {
					if (d.selected) return "#FF5F1F";
					if (
						d.properties?.background_paragraph &&
						d.properties?.overview_of_the_hack &&
						d.properties?.damage_assessments_and_fallout &&
						d.properties?.security_measures
					)
						return "green";
					if (d.properties?.event_name && d.properties?.overview) return "#87CEFA";
					if (d.properties?.is_main_conflict) return "black";
					if (d.properties?.paragraph && d.properties?.title) return "#FFA500";
					if (d.properties?.economic_title && d.properties?.details) return "blue";
					if (d.properties?.wonder_title && d.properties?.overview) return "#00FF80";
					if (d.properties?.infrastructure_title && d.properties?.summary) return "brown";
					if (d.properties?.spaceport_title && d.properties?.description) return "#8A2BE2";
					if (relatedPoints.includes(d)) return "purple";
					return "blue";
				}}
				pointRadius={(d) => (d.properties?.is_main_conflict ? 0.5 : 0.7)}
				pointAltitude={(d) =>
					!d.properties?.is_main_conflict ? (d.selected ? 0.06 : 0.04) : 0.08
				}
				enablePointerInteraction={true}
				onPointClick={(p) => handlePointClick(p, "map")}
			/>

			{/* The detail popup container */}
			{view === "earth" && bottomMode === BOTTOM_MODE.DETAILS && selectedPoint && (
				<div style={popupStyles.container}>
					<div style={popupStyles.header}>
						{selectedPoint.properties?.title ||
							selectedPoint.properties?.country ||
							selectedPoint.properties?.event_name ||
							selectedPoint.properties?.economic_title ||
							selectedPoint.properties?.wonder_title ||
							selectedPoint.properties?.infrastructure_title ||
							selectedPoint.properties?.spaceport_title ||
							"Unknown Location"}
					</div>
					<div
						style={{
							...popupStyles.content,
							fontFamily: "Calibri, sans-serif",
							fontSize: "14px",
							lineHeight: "1.4",
							color: "white",
						}}
					>
						{renderContent()}
					</div>
					<div style={popupStyles.footer}>
						<button
							onClick={() => {
								setSelectedPoint(null);
								setRelatedPoints([]);
								setContextParagraph("");
								setRelatedHeader("");
								setMainConflictPoint(null);

								if (detailSource === "list") {
									setBottomMode(BOTTOM_MODE.ITEMS);
								} else {
									setBottomMode(BOTTOM_MODE.NONE);
									setShowSentry(true);
								}
							}}
							style={{
								...popupStyles.closeButton,
								fontSize: "12px",
								padding: "3px 6px",
							}}
						>
							{detailSource === "list" ? "Back" : "Close"}
						</button>
					</div>
				</div>
			)}

			{/* The merged items container */}
			{view === "earth" && bottomMode === BOTTOM_MODE.ITEMS && (
				<div style={popupStyles.container}>
					<div style={popupStyles.header}>Recent News</div>
					<div
						style={{
							...popupStyles.content,
							fontFamily: "Calibri, sans-serif",
							fontSize: "14px",
							lineHeight: "1.4",
							color: "white",
						}}
					>
						{renderMergedItems()}
					</div>
				</div>
			)}

			{/* If user is on the Moon */}
			{view === "moon" && moonData && (
				<div style={popupStyles.container}>
					<div style={popupStyles.header}>{moonData.title || "Moon Watch"}</div>
					<div style={popupStyles.content}>{renderMoonData()}</div>
				</div>
			)}

			{/* "Open News" button near bottom center if container is closed
			    Hide if we're in focus mode */}
			{view === "earth" && bottomMode === BOTTOM_MODE.NONE && !focusMode && (
				<button
					style={{
						position: "absolute",
						bottom: "160px",
						left: "50%",
						transform: "translateX(-50%)",
						zIndex: 999,
						...popupStyles.closeButton,
					}}
					onClick={() => openNewsEnvironmentCyber()}
				>
					Open News
				</button>
			)}

			{/* SENTRY container, visible by default on Earth, hidden if showSentry=false */}
			{view === "earth" && showSentry && (
				<div
					style={{
						position: "absolute",
						bottom: 5,
						left: "50%",
						transform: "translateX(-50%)",
						width: "100%",
					}}
				>
					{renderSentryContainer()}
				</div>
			)}
		</div>
	);
}

export default Earth;