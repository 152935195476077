// styles.js
export const hudStyles = {
    container: {
        position: "absolute",
        top: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        background: "rgba(0, 0, 50, 0.8)",
        color: "white",
        padding: "4px 8px",
        borderRadius: "8px",
        fontSize: "21px",
        fontWeight: "bold",
        fontFamily: "Calibri, sans-serif",
        boxShadow: "0 0 15px rgba(0, 191, 255, 0.5)",
        zIndex: 1000,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        border: "2px solid #00BFFF",
        width: "75%",
        maxWidth: "1200px",
        boxSizing: "border-box",
    },
    button: {
        background: "rgba(0, 0, 50, 0.8)",
        color: "white",
        border: "2px solid #00BFFF",
        padding: "5px 10px",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "14px",
        boxShadow: "0 0 10px rgba(0, 191, 255, 0.5)",
    },
};

export const popupStyles = {
    container: {
        position: "absolute",
        bottom: "45px",
        left: "10%",
        right: "10%",
        background: "rgba(0, 0, 50, 0.8)",
        color: "white",
        padding: "10px",
        borderRadius: "10px",
        boxShadow: "0 0 20px rgba(0, 191, 255, 0.5)",
        zIndex: 1000,
        textAlign: "left",
        maxHeight: "35vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        border: "2px solid #00BFFF",
    },
    header: {
        background: "rgba(0, 0, 50, 0.8)",
        padding: "10px 0",
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Calibri, sans-serif",
        borderBottom: "1px solid #555",
        zIndex: 1,
        textAlign: "center",
        position: "sticky",
        top: 0,
        boxShadow: "0 0 10px rgba(0, 191, 255, 0.5)",
    },
    content: {
        padding: "10px",
        overflowY: "auto",
        flexGrow: 1,
        textAlign: "left",
        lineHeight: "1.6",
    },
    footer: {
        background: "rgba(0, 0, 50, 0.8)",
        padding: "10px 0",
        textAlign: "center",
        zIndex: 1,
        borderTop: "1px solid #555",
        position: "sticky",
        bottom: 0,
        boxShadow: "0 0 10px rgba(0, 191, 255, 0.5)",
    },
    closeButton: {
        background: "rgba(0, 0, 50, 0.8)",
        color: "white",
        border: "2px solid #00BFFF",
        padding: "8px 15px",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "14px",
        boxShadow: "0 0 10px rgba(0, 191, 255, 0.5)",
    },
    context: {
        marginTop: "20px",
        padding: "10px",
        borderRadius: "8px",
        background: "rgba(0, 0, 50, 0.8)",
        border: "2px solid #00BFFF",
        color: "white",
        fontSize: "14px",
        lineHeight: "1.5",
        textAlign: "left",
        boxShadow: "0 0 15px rgba(0, 191, 255, 0.5)",
    },
};